<template>
  <div class="wrapper">
      <v-expand-transition>
        <router-view />
      </v-expand-transition>
  </div>
</template>
<script>
import SubHeader from "@/components/SubHeader";
import FormDialogComponent from '@/components/dialogs/FormDialogComponent';
import getForm from '@/mixins/mixins';
import refreshData from '@/mixins/mixins';

export default {
  name: "User",
  components: {
    SubHeader,
    FormDialogComponent
  },
  mixins:[getForm, refreshData],
  data() {
    return {
      parents: [],
      formUrl: "",
      form: null,
      filters:{},
      apiUrl:"",
      mutation: "",
    };
  },
  methods: {
  },
  computed: {
   
  },
  mounted() {
  },
};
</script>
